module.exports = [{
      plugin: require('../.yarn/cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-7cb4df8ed5.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:300,400,500,700&display=swap"]}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-3f07a90822/0/cache/gatsby-plugin-canonical-urls-npm-5.14.0-7dc58a5e6a-966824c042.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://morrisoncole.co.uk"},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-2bc9512c5a/0/cache/gatsby-remark-autolink-headers-npm-6.14.0-1f93292f8e-9bd79d5209.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"maintainCase":false,"removeAccents":true,"isIconAfterHeader":true,"icon":"<svg\n                  aria-hidden=\"true\"\n                  version=\"1.1\"\n                  viewBox=\"0 0 16 16\"\n                  width=\"0.7em\"\n                  height=\"0.7em\"\n                  focusable=\"false\"\n                  fill=\"currentColor\"\n                >\n                  <path\n                    d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"\n                  ></path>\n                </svg>","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-de082bfe23/0/cache/gatsby-remark-images-npm-7.14.0-a55a0fccfe-31b4418441.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":false,"withAvif":true,"withWebp":true,"srcSetBreakpoints":[600,900,1200],"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-7221bf859f/0/cache/gatsby-plugin-google-tagmanager-npm-5.14.0-a64492daa5-f1a96443b5.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WCT4PHF","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"environment":"production"}},"routeChangeEventName":"route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-layout-virtual-8178f37cc5/0/cache/gatsby-plugin-layout-npm-4.14.0-6864ac6f8c-d63ca9609d.zip/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/morrisoncole.co.uk/morrisoncole.co.uk/src/components/layout.tsx"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-aa82afc188/0/cache/gatsby-plugin-manifest-npm-5.14.0-8a89b421cf-9663fdaca2.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Morrison Cole","short_name":"Morrison Cole","start_url":"/","display":"standalone","icon":"content/favicon.ico","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"84fa2165517dbd4bfcf9faa5fa1ac70e"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-edd63ffb45/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
